<template>
    <div class="device-modal-box" v-if="imageViewVisible">
        <div class="modal-title"><span>{{ imageName }}</span></div>
        <a class="modal-clear" @click="onImageOff"><Icon type="md-add" size="30" /></a>
        <div class="modal-image-box" @mousewheel="onMousewheel">
            <Spin fix v-if="imageLoading">
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                <div>加载中...</div>
            </Spin>
            <div v-if="!imageLoading" class="image-box-mouse" @mousedown="onMouseDown"
                 @mouseup="onMouseUp"
                 @mouseout="onMouseUp"
                 @mousemove="onMouseMove"
                 :style="'width:'+(imageWidth*(imageRatio/100))+'px;height:'+(imageHeight*(imageRatio/100))+'px;left:'+imageLeft+'px;top:'+imageTop+'px'">
              <img :src="imageUrl"/>
            </div>
        </div>
        <div class="modal-control-box">
            <Tooltip content="放大"><a href="javascript:" @click="onImageEnlarge"><i class="enlarge"></i></a></Tooltip>
            <Tooltip content="缩小"><a href="javascript:" @click="onImageNarrow"><i class="narrow"></i><span class="b-before"></span></a></Tooltip>
            <Tooltip content="图片还原"><a href="javascript:" @click="onReduction">{{ imageRatio }}%<span class="b-before"></span></a></Tooltip>
        </div>
    </div>
</template>

<script>
import ViewImage from './viewImage'
export default ViewImage
</script>
<style lang="less">
@import "viewImage";
</style>
