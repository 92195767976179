<template>
    <div class="layout-record-info">
        <div class="layout-device-bread">
            <Breadcrumb>
                <BreadcrumbItem to="/workflow/record/inspection/list">安全巡检记录 </BreadcrumbItem>
                <BreadcrumbItem>记录详情 </BreadcrumbItem>
            </Breadcrumb>
        </div>
        <!--s: Search 区域-->
        <div class="device-list-search">
<!--            <div class="list-back"><a href="javascript:" @click="onBack"><Icon type="ios-arrow-round-back" />返回</a></div>-->
            <div class="list-info">
                <ul>
                    <li>任务名称：{{ taskList.tasksName }}</li>
                    <li>记录编号：{{ taskList.recordCode }}</li>
                    <li>任务编号：{{ taskList.tasksCode }}</li>
                    <li>流程编号：{{ taskList.flowCode }}</li>
                    <li>开始时间：{{ taskList.beginTime }}</li>
                    <li>完成时间：{{ taskList.finishTime || '/' }}</li>
                    <li>执行人：{{ taskList.executorUser.userName }}</li>
                    <li>执行状态：{{ taskList.status === 0 ? '未执行' : '已执行' }}</li>
                </ul>
            </div>
        </div>
        <!--e: Search 区域-->
        <!--s: Data 区域-->
        <div class="device-list record-table">
            <Tabs :animated="false" @on-click="onTabsClick">
                <Tab-pane label="流程步骤">
                    <div class="record-table-body">
                        <div class="table-head">
                            <ul>
                                <li style="flex: none; width:60px;justify-content: center">序号</li>
                                <li>步骤名称</li>
                                <li>步骤内容</li>
                                <li>耗时</li>
                                <li>用户操作</li>
                                <li style="flex: none; width:160px;text-align: center">反馈信息</li>
                            </ul>
                        </div>
                        <div class="table-body">
                            <div class="table-ul" :key="key" v-for="(record,key) in recordInfoArray">
                                <div class="table-data">
                                    <div class="table-li" style="flex: none; width:60px;justify-content: center"><div class="cell">{{ key+1 }}</div></div>
                                    <div class="table-li"><Icon custom="table-icon"/><div class="cell">{{ record.nodeName }}</div></div>
                                    <div class="table-li"><div class="cell">
                                        <a href="javascript:" v-if="record.stepContentList.length!==0" @click="onRecordInfoRow(record)">查看</a>
                                        {{ record.stepContentList.length===0 &&  record.nodeCode !=='NODE_BATCH_INPUT' && record.nodeCode !=='NODE_BATCH_OPERATION' ? '/':''}}
                                        <a href="javascript:" class="visible" @click="onRecordVisible(key)" v-if="record.nodeCode === 'NODE_BATCH_INPUT' || record.nodeCode === 'NODE_BATCH_OPERATION'">{{ record.visible?'收起':'展开'}}<Icon :type="record.visible?'ios-arrow-up':'ios-arrow-down'" /></a>
                                    </div>
                                    </div>
                                    <div class="table-li"><div class="cell">{{ record.timeConsuming }}s</div></div>
                                    <div class="table-li"><div class="cell">{{ record.nodeClickButtonName }}</div></div>
                                    <div class="table-li" style="flex: none; width:160px;justify-content: center">
                                        <div class="cell">
                                            {{ record.stepFeedbackList.length === 0 || (record.stepFeedbackList.length !== 0 && (record.nodeCode === 'NODE_BATCH_INPUT' || record.nodeCode === 'NODE_BATCH_OPERATION'))?'/':'' }}
                                            <a href="javascript:" @click="onQuickFeedback(record,'video')" v-if="record.stepFeedbackList.filter(item=>item.dataType === 2).length!==0 && record.nodeCode !== 'NODE_BATCH_INPUT' && record.nodeCode !== 'NODE_BATCH_OPERATION'"><Icon type='ios-videocam' size="20"/>{{ record.stepFeedbackList.filter(item=>item.dataType === 2).length }}</a>
                                            <a href="javascript:" @click="onQuickFeedback(record,'camera')" v-if="record.stepFeedbackList.filter(item=>item.dataType === 1).length!==0 && record.nodeCode !== 'NODE_BATCH_INPUT' && record.nodeCode !== 'NODE_BATCH_OPERATION'"><Icon type='md-camera' size="20"/>{{ record.stepFeedbackList.filter(item=>item.dataType === 1).length }}</a>
                                            <a href="javascript:" @click="onQuickFeedback(record,'mic')" v-if="record.stepFeedbackList.filter(item=>item.dataType === 3).length!==0 && record.nodeCode !== 'NODE_BATCH_INPUT' && record.nodeCode !== 'NODE_BATCH_OPERATION'"><Icon type='ios-mic' size="20"/>{{ record.stepFeedbackList.filter(item=>item.dataType === 3).length }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-item" v-if="(record.nodeCode ==='NODE_BATCH_INPUT' || record.nodeCode ==='NODE_BATCH_OPERATION') && record.visible">
                                    <div class="table-data" :key="index" v-for="(batch,index) in record.nodeCode ==='NODE_BATCH_INPUT'?record.batchInputList:record.nodeCode ==='NODE_BATCH_OPERATION'?record.batchOperateList:''">
                                        <div class="table-li" style="flex: none; width:60px;justify-content: center"><div class="cell"></div></div>
                                        <div class="table-li"><div class="cell"><font>{{ index+1 }}</font>{{ batch.content }}</div></div>
                                        <div class="table-li"><div class="cell">
                                            <a href="javascript:" @click="onRecordInfoRow(batch)">查看</a>
                                        </div>
                                        </div>
                                        <div class="table-li"><div class="cell"></div></div>
                                        <div class="table-li"><div class="cell">{{ record.nodeCode ==='NODE_BATCH_INPUT'?batch.recordingTextContent:record.nodeCode ==='NODE_BATCH_OPERATION'?batch.clickButtonName:''}}{{ record.nodeCode ==='NODE_BATCH_INPUT' && batch.inputType === 'number'?batch.inputNumberUnit:'' }}</div></div>
                                        <div class="table-li" style="flex: none; width:160px;justify-content: center">
                                            <div class="cell">
                                                {{ record.stepFeedbackList.length === 0 || ( record.stepFeedbackList.length !==0 && record.stepFeedbackList.filter(item=>item.batchId === batch.batchId).length === 0)?'/':'' }}
                                                <a href="javascript:" @click="onQuickFeedback(record,'video',batch)" v-if="record.stepFeedbackList.filter(item=>item.dataType === 2 && item.batchId === batch.batchId).length !== 0"><Icon type='ios-videocam' size="20"/>{{ record.stepFeedbackList.filter(item=>item.dataType === 2 && item.batchId === batch.batchId).length }}</a>
                                                <a href="javascript:" @click="onQuickFeedback(record,'camera',batch)" v-if="record.stepFeedbackList.filter(item=>item.dataType === 1 && item.batchId === batch.batchId).length !== 0"><Icon type='md-camera' size="20"/>{{ record.stepFeedbackList.filter(item=>item.dataType === 1 && item.batchId === batch.batchId).length }}</a>
                                                <a href="javascript:" @click="onQuickFeedback(record,'mic',batch)" v-if="record.stepFeedbackList.filter(item=>item.dataType === 3 && item.batchId === batch.batchId).length !== 0"><Icon type='ios-mic' size="20"/>{{ record.stepFeedbackList.filter(item=>item.dataType === 3 && item.batchId === batch.batchId).length }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab-pane>
                <Tab-pane label="查看录像">
                    <div class="record-video-no-data" v-if="recordVideoArray.length === 0"></div>
                    <div class="record-video-body" v-if="recordVideoArray.length!==0">
                        <div class="video-content">
                            <div class="content">
                                <Spin fix v-if="recordVideoLoading"></Spin>
                                <div class="video">
                                    <span class="video-no-data" v-if="!videoSrc"><Icon type="ios-barcode-outline" size="60"/></span>
                                    <video v-if="videoSrc" width="100%" controls>
                                        <source :src="videoSrc" type="video/mp4">
                                        您的浏览器不支持Video标签。
                                    </video>
                                </div>
                            </div>
                        </div>

                        <!--s: 视频列表-->
                        <div class="video-list">
                            <div class="video-list-title">
                                <span>录像列表<font>（{{ videoKey }}/{{ recordVideoArray.length }}）</font></span>
                            </div>
                            <div class="video-list-body">
                                <Spin fix v-if="recordVideoListLoading"></Spin>
                                <div class="video-header">
                                    <span class="number"> </span>
                                    <span class="start">开始时间</span>
                                    <span class="end">结束时间</span>
                                    <span class="time">时长</span>
                                </div>
                                <div class="video-body">
                                    <div class="video-body-li" :class="recordSelectObj.id === video.id?'active':''" :key="key" v-for="(video,key) in recordVideoArray" @click="onSelectVideo(video,key)">
                                        <div class="number"><Icon type="ios-barcode-outline" size="20" v-if="recordSelectObj.id === video.id" />{{ recordSelectObj.id !== video.id?key+1:'' }}</div>
                                        <div class="start">{{ video.beginTime }}</div>
                                        <div class="end">{{ video.finishTime}}</div>
                                        <div class="time">{{ video.timeConsuming }}s</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--e: 视频列表-->
                    </div>
                </Tab-pane>
            </Tabs>
        </div>
        <!--e: Data 区域-->

        <Modal
            v-model="recordModalVisible"
            :title="recordModalTitle"
            width="600"
            footer-hide>
            <img width="100%" :src="imageSrc" class="record-image"/>
        </Modal>

        <Modal
            v-model="recordInfoVisible"
            title="步骤内容"
            class="record-box"
            width="600"
            footer-hide>
            <!--            <div class="device-modal-record">-->
            <!--                <span class="label">标题内容：</span>-->
            <!--                <div class="text-label">{{ recordInfoObj.titleContent }}</div>-->
            <!--            </div>-->
            <div class="device-modal-record" :key="key" v-for="(recordInfo,key) in recordInfoObj">
                <span class="label">{{ recordInfo.type === 'text'? '作业内容':recordInfo.type === 'image'?'图片提示':'作业标准'}} <a href="javascript:" v-if="recordInfo.type === 'image'" @click="onLookImage(recordInfo.content)"><Icon custom="look-image"></Icon>查看大图</a></span>
                <div class="text-label" v-if="recordInfo.type === 'text'">{{ recordInfo.content }}</div>
                <div class="text-label" v-if="!recordInfo.type">{{ recordInfo.standard }}</div>
                <div class="image-label image-cell" v-if="recordInfo.type === 'image'">
                    <img :src="recordInfo.content"/>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="stepFeedbackVisible"
            title="步骤反馈"
            width="600"
            footer-hide>
            <!--            <div class="device-modal-record">-->
            <!--                <span class="label">标题内容：</span>-->
            <!--                <div class="text-label">{{ recordInfoObj.titleContent }}</div>-->
            <!--            </div>-->
            <div class="modal-box" v-if="!stepFeedbackObj.nodeNextTriggerList">
                <div class="device-modal-record" v-if="stepFeedbackObj.workFileType === 'image'">
                    <span class="label">拍照</span>
                    <div class="image-label">
                        <img width="100%" :src="stepFeedbackObj.workFileUrl" class="record-image"/>
                    </div>
                </div>
                <div class="device-modal-record" v-if="stepFeedbackObj.workFileType === 'voice'">
                    <span class="label">录音</span>
                    <div class="voice-box">
                        <div class="audio">
                            <audio controls :src="stepFeedbackObj.workFileUrl">
                                您的浏览器不支持 audio 元素。
                            </audio>
                        </div>
                        <div class="audio-box">
                            <p class="time">{{ stepFeedbackObj.workFileUploadTime }}</p>
                            <a href="javascript:" class="text-down" @click="audioVisible = !audioVisible">文字转换 <Icon size="16" :type="audioVisible?'md-arrow-dropup':'md-arrow-dropdown'" /></a>
                            <div class="audio-text" v-if="audioVisible">{{ stepFeedbackObj.operationContent }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-box" v-if="stepFeedbackObj.nodeNextTriggerList">
                <div class="box-list" :key="key" v-for="(trigger,key) in stepFeedbackObj.nodeNextTriggerList">
                    <div class="device-modal-record" v-if="trigger.operationType === 0">
                        <span class="label">拍照</span>
                        <div class="image-label">
                            <img width="100%" :src="trigger.followFileUrl" class="record-image"/>
                        </div>
                    </div>
                    <div class="device-modal-record" v-if="trigger.operationType === 1">
                        <span class="label">录音</span>
                        <div class="voice-box">
                            <div class="audio">
                                <audio controls :src="trigger.followFileUrl">
                                    您的浏览器不支持 audio 元素。
                                </audio>
                            </div>
                            <div class="audio-box">
                                <p class="time">{{ trigger.createTime }}</p>
                                <a href="javascript:" class="text-down" @click="audioVisible = !audioVisible">文字转换 <Icon size="16" :type="audioVisible?'md-arrow-dropup':'md-arrow-dropdown'" /></a>
                                <div class="audio-text" v-if="audioVisible">{{ trigger.feedbackContent }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="quickFeedbackVisible"
            title="反馈信息"
            width="500"
            class="record-box"
            @on-visible-change="onVisiblePlayChange"
            footer-hide>
            <!--            <div class="device-modal-record">-->
            <!--                <span class="label">标题内容：</span>-->
            <!--                <div class="text-label">{{ recordInfoObj.titleContent }}</div>-->
            <!--            </div>-->
            <div class="device-modal-record" v-if="videoList.length!==0">
                <span class="label">录像<font>（{{ quickNumber }}/{{ videoList.length }}）</font></span>
                <div class="image-list">
                    <a href="javascript:" v-if="videoList.length >1" class="image-l" @click="onPreviousPage('video')"><Icon size="26" type="ios-arrow-back" /></a>
                    <a href="javascript:" v-if="videoList.length >1" class="image-r" @click="onNextPage('video')"><Icon size="26" type="ios-arrow-forward" /></a>
                    <div class="image-box">
                        <div class="image-content" :style="'width:'+(428*videoList.length)+'px; transform: translate3d(-'+(428*(quickNumber-1))+'px,0px,0px)'">
                            <div class="video-box" :key="key" v-for="(pictureRecording,key) in videoList">
                                <video width="100%" height="100%" controls :autoplay="false" :src="pictureRecording.dataResourceUrl"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="device-modal-record" v-if="quickList.length!==0">
                <span class="label">照片<font>（{{ quickNumber }}/{{ quickList.length }}）</font><a href="javascript:" @click="onLookMuchImage"><Icon custom="look-image"></Icon>查看大图</a></span>
                <div class="image-list">
                    <a href="javascript:" v-if="quickList.length >1" class="image-l" @click="onPreviousPage('image')"><Icon size="26" type="ios-arrow-back" /></a>
                    <a href="javascript:" v-if="quickList.length >1" class="image-r" @click="onNextPage('image')"><Icon size="26" type="ios-arrow-forward" /></a>
                    <div class="image-box">
                        <div class="image-content" :style="'width:'+(428*quickList.length)+'px; transform: translate3d(-'+(428*(quickNumber-1))+'px,0px,0px)'">
                            <div class="box" :key="key" v-for="(photograph,key) in quickList">
                                <img :src="photograph.dataResourceUrl"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="device-modal-record voice-record" v-if="voiceList.length!==0">
                <span class="label">录音（{{ voiceList.length }}）</span>
                <div class="voice-box">
                    <li :key="key" v-for="(voice,key) in voiceList">
                        <div class="voice-box-audio" :class="voice.playStatus?'play':''">
                            <a href="javascript:" @click="onPlay(voice.dataResourceUrl,key)"><Icon :type="voice.playStatus?'md-pause':'md-play'" size="14"/></a>
                            <span class="time">{{ voice.createTime }}</span>
                        </div>
                        <div class="voice-box-text">
                            <span class="title">转文字：</span>
                            <p class="text">{{ voice.recordingTextContent }}</p>
                        </div>
                    </li>
                    <!--e: 录音模块-->
                    <audio controls :src="audioUrl" class="voice" ref="audioPlayer">
                      您的浏览器不支持 audio 元素。
                    </audio>
                    <!--                    <div class="audio">-->
                    <!--                      <audio  controls :src="voice.dataResourceUrl">-->
                    <!--                        您的浏览器不支持 audio 元素。-->
                    <!--                      </audio>-->
                    <!--                    </div>-->
                    <!--                    <div class="audio-box">-->
                    <!--                        <p class="time">{{ voice.createTime }}</p>-->
                    <!--                        <a href="javascript:" class="text-down" @click="onVoiceList(key)">文字转换 <Icon size="16" :type="voice.visible?'md-arrow-dropup':'md-arrow-dropdown'" /></a>-->
                    <!--                        <div class="audio-text" v-if="voice.visible">{{ voice.recordingTextContent }}</div>-->
                    <!--                    </div>-->
                </div>
            </div>
        </Modal>
        <Modal
            v-model="voiceModalVisible"
            title="语音"
            width="400"
            @on-visible-change="onVisibleChange"
            footer-hide>
            <div class="voice-modal">
                <div class="audio">
                    <audio controls :src="audioSrc" id="audio">
                        您的浏览器不支持 audio 元素。
                    </audio>
                </div>
                <div class="voice-text">
                    <h1 class="title">转文字</h1>
                    <div class="text">
                        {{ (inputTypeCode!=='' && inputTypeCode==='text') || inputTypeCode === ''? audioText:'' }}
                        <div class="text-style" v-if="inputTypeCode === 'number'">
                            <span>{{ audioText }}</span>
                            <span class="unit">{{ unitType }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        <!--s: 执行记录批量查看-->
        <Drawer
            v-model="recordModalListVisible"
            title="批量操作"
            class="record-table"
            width="800">
            <Table :columns="recordModalListColumns" stripe="stripe" :data="batchArray" >
                <template slot="序号" slot-scope="{ index }">
                    {{ index+1 }}
                </template>
                <template slot="图片" slot-scope="{ row }">
                    <a href="javascript:" v-if="row.batchNodeFileUrl !==''" class="batch-underline" @click="onImageAmplify(row.batchNodeFileUrl)">查看图片</a>
                    {{ row.batchNodeFileUrl === ''?'/':'' }}
                </template>
                <template slot="执行操作" slot-scope="{ row }">
                    {{ row.feedback || '/' }}
                </template>
                <template slot="操作" slot-scope="{ row }">
                    {{ !row.nodeNextTriggerList || (row.nodeNextTriggerList && row.nodeNextTriggerList.length === 0)?'/':''  }}
                    <a href="javascript:" v-if="row.nodeNextTriggerList && row.nodeNextTriggerList.length===1" @click="onTriggerCheck(row.nodeNextTriggerList[0])">查看</a>
                    <Dropdown v-if="row.nodeNextTriggerList && row.nodeNextTriggerList.length>1">
                        <a href="javascript:void(0)">
                            查看
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem :key="key" v-for="(triggerList,key) in row.nodeNextTriggerList" @click.native="onTriggerCheck(triggerList)">{{ triggerList.followFeedback }}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </template>
            </Table>
        </Drawer>
        <!--e: 执行记录批量查看-->
        <!--s: 执行记录批量输入查看-->
        <Drawer
            v-model="batchInputListVisible"
            title="批量输入"
            class="record-table"
            width="873">
            <Table :columns="batchInputRecordColumns" stripe="stripe" :data="batchInputArray" >
                <template slot="序号" slot-scope="{ index }">
                    {{ index+1 }}
                </template>
                <template slot="节点操作" slot-scope="{ row }">
                    <div class="record-box">
                        <div class="record-input">
                            {{ (row.inputTypeCode!=='' && row.inputTypeCode==='text') || row.inputTypeCode === ''? row.content:'' }}
                            <div class="text-style" v-if="row.inputTypeCode === 'number'">
                                <span>{{ row.content }}</span>
                                <span class="unit">{{ row.contentUnit }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </Table>
        </Drawer>
        <!--e: 执行记录批量输入查看-->

        <!--s: Modal 查看大图-->
        <ViewImage v-model="viewImageVisible" :imageName="imageName" :imageUrl="imageSrc"/>
        <!--e: Modal 查看大图-->

    </div>
</template>
<script>
import RecordInspectionInfo from './info'
export default RecordInspectionInfo
</script>
<style lang="less">
@import "info";
</style>
