import Vue from 'vue'
import { Tooltip,Spin } from "view-design";
Vue.component('Tooltip', Tooltip);
Vue.component('Spin', Spin);
/**
 * 查看图片
 * @type {{ List }}
 */
const ViewImage = {
    name:'ViewImage',
    props:{
        /**
         * 监听传过来的model值
         */
        value: {
            type: Boolean,
            default: false
        },
        /**
         * 图片地址
         */
        imageUrl:{
            type: String,
        },
        /**
         * 图片名称
         */
        imageName:{
            type: String,
        },
    },
    data() {
        return {
            imageLoading:false,
            imageViewVisible:false,
            drawing:false, //是否绘制
            imageWidth:0,
            imageHeight:0,
            imageLeft:0,
            imageTop:0,
            currentX:0, //记录落点的x坐标
            currentY:0, //记录落点的Y坐标
            imageRatio:100,
        }
    },
    created() {
        
    },
    methods:{
        /**
         * 鼠标滚轮事件
         * @param e
         */
        onMousewheel(e) {
            if(e.wheelDelta > 0){
                this.onImageEnlarge()
            }else{
                this.onImageNarrow()
            }
        },
        /**
         * 记录按下的事件
         * @param e
         * @returns {{x: number, y: number}}
         */
        onMouseDown(e) {
            this.drawing = true;
            this.currentX = e.offsetX
            this.currentY = e.offsetY
        },
        /**
         * 记录抬起的事件
         * @param e
         */
        onMouseUp() {
            if (!this.drawing) { return; }
            this.drawing = false;
        },
        /**
         * 记录移动的事件
         * @param e
         */
        onMouseMove(e) {
            if (!this.drawing) { return; }
            this.imageLeft = e.clientX - this.currentX
            this.imageTop = e.clientY - this.currentY
        },
        /**
         * 图片放大比例
         */
        onImageEnlarge() {
            if((this.imageRatio + 25 ) > 1000){
                this.imageRatio = 1000
                return
            }
            this.imageRatio+=25

            this.imageLeft -= (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop -= (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 图片缩小比例
         */
        onImageNarrow() {
            if((this.imageRatio - 25 ) < 25){
                this.imageRatio = 25
                return
            }
            this.imageRatio-=25
            this.imageLeft += (this.imageWidth*(this.imageRatio/100) - this.imageWidth*((this.imageRatio-25)/100))/2
            this.imageTop += (this.imageHeight*(this.imageRatio/100) - this.imageHeight*((this.imageRatio-25)/100))/2
        },
        /**
         * 还原图片
         */
        onReduction() {
            this.imageRatio = 100
            this.imageLeft = (window.innerWidth - this.imageWidth*(this.imageRatio/100))/2
            this.imageTop = (window.innerHeight - this.imageHeight*(this.imageRatio/100))/2
        },
        /**
         * 图片初始化
         * @param src
         */
        onImageLoad(src){
            this.imageLoading = true
            let image = new Image();
            image.src = src
            image.onload = ()=>{
                this.imageLoading = false
                if(image.height>window.innerHeight){
                    if(image.width>image.height){
                        this.imageWidth = image.height/image.width*window.innerHeight
                    }{
                        this.imageWidth = image.width/image.height*window.innerHeight
                    }
                    this.imageHeight = window.innerHeight
                }else{
                    this.imageWidth = image.width
                    this.imageHeight = image.height
                }
                this.imageLeft = (window.innerWidth- this.imageWidth)/2
                this.imageTop = (window.innerHeight- this.imageHeight)/2
            }
        },
        /**
         * 关闭
         */
        onImageOff(){
            this.imageViewVisible = false;
            this.imageRatio = 100
            this.$emit('input', false);
        },
    },
    watch: {
        value(val) {
            this.imageViewVisible = val;
            if(val){
                this.onImageLoad(this.imageUrl)
            }
        },
    }
}
export default ViewImage
